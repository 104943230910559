import axios from 'axios'
// export const url_backend = `http://127.0.0.1:8000`
export const url_backend = `https://allianceformacion-api.smartbits-es.com`
// export const url_backend = `https://allianceformacion2-api.smartbits-es.com`
// export const url_backend = `https://allianceformacion3-api.smartbits-es.com`
export const url_moodle = `https://moodlealliance.smartbits-es.com`
export const url_local_scorm = `https://scormsalliance.smartbits-es.com`

const url_media = `${url_backend}/api/media/documents`
export const url_mediaa = `${url_backend}/api/media/documents`
export const url_empresa = `${url_backend}/api/media/enterprise`
const url_certificados = `${url_backend}/api/media/certificados`
export const url_certificadoss = `${url_backend}/api/media/certificados`
export const url_mensajes = `${url_backend}/api/media/mensajes`
export const url_scorm = `${url_backend}/api/media/scorm`
export const loginURL = `${url_backend} /login`;

export const scorm = {
    users: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/scorm/users`)
    },
    cursos: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/scorm/cursos`)
    },
    userByMail: function(email, codificacion, recurso) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/scorm/userByMail`, {email: email, cursoCodificacion: codificacion, recurso: recurso})
    },
    getStatus: function(cid, uid) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/scorm/getStatus`, {cid: cid, uid: uid})
    },
    compruebaCurso: function(codificacion) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/scorm/compruebaCurso`, {cursoCodificacion: codificacion})
    },
    show: function(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/localscorm/show/${id}`)
    },
    status: function(scormId, userId) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        const datos = {
            scoId: scormId,
            userId: userId,
        }
        return axios.post(url_backend + `/api/localscorm/status`, datos)
    },
    url: function(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.get(url_backend + `/api/media/scorm/${id}`)
    }
}
export const moodle = {
    dameScorm: function(codificacion, resource, mail) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/moodle/dameScorm`, {
            codificacion: codificacion,
            resource: resource,
            mail: mail
        })
    },
    verScorm: function(mode, scoid, cm, currentorg) {
        return axios.post(`${url_moodle}/mod/scorm/player.php`, {
            mode: mode,
            scoid: scoid,
            cm: cm,
            currentorg: currentorg
        }, {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        })
    }
}

export const auth = {
    login: function(email, password){
        const user = {
            email: email,
            password: password
        }
        return axios.post(url_backend+`/api/auth/login`, user)
    },
    forgotPass: function(email){
        const user = {
            email: email
        }
        return axios.post(url_backend+`/api/auth/forgot-password`, user)
    },
    resetPass: function(token, email, password, password_confirmation){
        return axios.post(url_backend+`/api/auth/reset-password`, {
            token: token,
            email: email,
            password: password,
            password_confirmation: password_confirmation
        })
    },
    logout: function(){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/auth/logout`)
    },
    editUser: function(id, name, apellidos, email, email_secundario,telefono) {
        const user = {
            name: name,
            apellidos: apellidos,
            email: email,
            email_secundario: email_secundario,
            telefono:telefono
        }
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/perfil/edit/${id}`, user)
    },
    cambioPass: function(id, password, new_password) {
        const user = {
            password: password,
            new_password: new_password
        }
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/perfil/changepass/1`, user)
    },
    preImgPerfil: function(datos) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/perfil/upload`, datos, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
        })
    },
    preImgPerfilResize: function(datos) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/perfil/uploadResize`, datos, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
        })
    },
    cambioImgPerfil: function(id, imagen) {
        const user = {
            imagen_nombre: imagen
        }
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/perfil/changeimg/${id}`, user)
    },
    dameUrlBack: function() {
        return url_backend;
    },
    dameUrlMedia: function() {
        return url_media;
    },
    dameUrlPdf: function() {
        return url_certificados;
    },
    dameTutoresMatriculas: function(idMatricula,idTutor) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/users/tutoresmatricula`,{
            idCourse: idMatricula,
            idTutor: idTutor,
        })
    },

    getProfile: function(){

        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")

        return axios.get(url_backend+`/api/auth/user-profile`)

    },

    verImgAuth: function(url) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        })
    }
}
export const data = {
    dameUser: function(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+id
        return axios.get(url_backend+`/api/auth/user-profile`)
    },
    dameUsuarios: function(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+id
        return axios.get(url_backend+`/api/users/users`)
    },
    getAdministradores: function (id) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + id
        return axios.get(url_backend + `/api/users/admins`)
    },
    dameImagen: function(id, image){
        axios.defaults.headers.common['Authorization'] = "Bearer "+id
        return axios.get(url_backend+`/api/media/documents/${image}`, {id:id})
    },
    dameTiposContacto: function() {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/mensajes/tipos`)
    },
    dameTipoContacto: function(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/mensajes/tipo/${id}`)
    },
    updateuserIntransferible(id, intransferible_pass) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/updateuserIntransferible/${id}`, {
    
            intransferible_pass:intransferible_pass
        })
    },
    guardaMensaje: function(id, tipo, texto) {
        const user = {
            texto: texto,
            tipo_id: tipo,
            emisor_id: id,
            receptor_id: 6
        }
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/mensajes/nuevo-contacto`, user)
    },
    guardaRedMensaje: function(id, destinatario, texto, tipo, adjuntos, idCurso = 0) {
        const user = {
            texto: texto,
            receptor_id: destinatario,
            emisor_id: id,
            tipo_id: tipo,
            adjuntos: adjuntos,
            idCurso: idCurso
        }
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/mensajes/nuevo-mensaje`, user)
    },
    guardaRedMensaje2: function(id, destinatario, texto, tipo, adjuntos) {
        const user = {
            texto: texto,
            receptor_id: destinatario,
            emisor_id: id,
            tipo_id: tipo,
            adjuntos: adjuntos
        }
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/mensajes/nuevo-mensajeAux`, user)
    },
    dameMensajesIni: function(id) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/mensajes/mensajesIni/${id}`)
    },
    dameMensajesConver: function(id, usuario) {
        const user = {
            mi_id: parseInt(id),
            user_id: usuario
        }
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/mensajes/mensajesConver`, user)
    },
    dameMensajes(id){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/mensajes/dameMensajes/${id}`)
    },
    leerMensajes(cod, user) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        const data = {
            cod: cod,
            user: user
        }
        return axios.post(url_backend+`/api/mensajes/leerMensajes`, data)
    },
    dameTemarios:function(id){

        return axios.get(url_backend+`/api/matriculas/damematricula/${id}`)
    },
    dameSeguimiento:function(authId,course){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/matriculas/dameseguimiento/${course}`)
    },
    updateSeguimiento:function(json,id){
      /*  const ids = {
            id : parseInt(authId),
            idCurso : course,
            curso : json[0]
        }*/

        return axios.post(url_backend+`/api/matriculas/updateseguimiento/${id}`,json)
    },
    uploadMsj: function(datos) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/mensajes/uploadMsj`, datos, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
        })
    },
    enterpriseUrl: function(url, campo) {
        return axios.post(url_backend+`/api/enterprises/enterpriseUrl`, {
            campo: campo,
            url: url
        })
    }
}
export const coursesData = {

    updateCourse: function(id, course){
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/courses/course/${id}`, course)
    },

    getGeneralQuiz: function(id){
        return axios.get(url_backend+`/api/cuestionarios_generales/cuestionariogeneral/${id}`)
    },
    getMatriculaConSeg: function(seg){
        return axios.get(url_backend+`/api/matriculas/getMatSeg/${seg}`)
    },
    getMatriculaComu: function(id) {
        let aux = parseInt(id)
        return axios.get(url_backend+`/api/matriculas/getMatComunicaciones/${aux}`)
    },
    setFechaMat: function(id) {
        const datos = {
            id: id
        }
        return axios.post(url_backend+`/api/matriculas/setFecha`, datos)
    },
    setEnviado: function(id, comunicacion) {
        const datos = {
            id: id,
            comunicacion: comunicacion
        }
        return axios.post(url_backend+`/api/matriculas/setEnviado`, datos)
    },
    send: function() {
        return axios.get(url_backend+`/api/comunicaciones/send`)
    },
    send30: function(nombre, mail, link, curso) {
        const datos = {
            nombre: nombre,
            mail: mail,
            link: link,
            curso: curso,
            logo: localStorage.getItem("logo")
        }
        return axios.post(url_backend+`/api/comunicaciones/30superado`, datos)
    },
    send60: function(nombre, mail, link, curso) {
        const datos = {
            nombre: nombre,
            mail: mail,
            link: link,
            curso: curso,
            logo: localStorage.getItem("logo")
        }
        return axios.post(url_backend+`/api/comunicaciones/60superado`, datos)
    },
    sendCom: function(nombre, mail, link, curso) {
        const datos = {
            nombre: nombre,
            mail: mail,
            link: link,
            curso: curso,
            logo: localStorage.getItem("logo")
        }
        return axios.post(url_backend+`/api/comunicaciones/superado`, datos)
    },
    generaPDF: function(id, fecha_ini, fecha_fin) {

        const datos = {
            fecha_ini: fecha_ini,
            fecha_fin: fecha_fin
        }
        return axios({
            url: url_backend+`/api/matriculas/pdf2/${id}`,
            method: 'POST',
            responseType: 'blob', // important
            data: datos
        })
    },
    finalizaCurso: function(id) {
        return axios.post(url_backend+`/api/matriculas/finalizaCurso`, {id: id})
    },
    dameCertificado: function(id){
        return axios.get(url_backend+`/api/matriculas/damecert/${id}`)
    },
    regLogCurso: function(id) {
        const datos = {
            id: id
        }
        return axios.post(url_backend+`/api/courses/regLogCurso`, datos)
    }
}

export const notasPersonales = {

    nuevaNota: function(nota) {
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/notas_personales/nota_personal`, nota)
    },
    getNota: function(idCurso, idRecurso){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.get(url_backend+`/api/notas_personales/nota_personales/${idCurso}/${idRecurso}`)
    },
    putNota: function(idNota, nota){
        axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend+`/api/notas_personales/nota_personal/${idNota}`, nota)
    }
}