import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment'
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import 'fontsource-roboto';
import { auth } from './data/Data';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Pages
const Login = React.lazy(() => import('./components/Login'));
const Autologin = React.lazy(() => import('./components/Autologin'));
const FirstChange = React.lazy(() => import('./components/FirstChange'));
const Cambiarpass = React.lazy(() => import('./components/Cambiarpass'));
const Aprendizaje = React.lazy(() => import('./components/Nav'));
const Mensajes = React.lazy(() => import('./components/Mensajes'));
const Perfil = React.lazy(() => import('./components/Perfil'));
const EndScorm = React.lazy(() => import('./components/EndScorm'));
const Cursos = React.lazy(() => import('./components/Cursos'));
const VisorComponent = React.lazy(() => import('./components/Visor/VisorComponent'));

const pathroute = window.location.pathname;
let listener = null;
let mouseStop = null;
let interval = null;
let Time = 900000; //15 minutos
function App() {
  const [openD, setOpenD] = useState(false);
  const [contador, setContador] = useState(120);
  const [contador2, setContador2] = useState(120);
  const [contadorFormat, setContadorFormat] = useState('02:00');
  function compruebaKey() {
    if (localStorage.getItem('auth') === null) {
      return false;
    }
    return true;
  }
  const logout = () => {
    handleClose()
    auth.logout()
      .then(response => {
        localStorage.removeItem('auth')
        localStorage.removeItem('id')
        setTimeout(function () {
          window.location.reload()
        }, 50)
      })
      .catch(error => {
      })
  }

  useEffect(() => {
    if (compruebaKey()) {
      console.log('entro');
      AddAlert()
    }
  }, [])

  const handleClickOpen = () => {
    //console.log(mouseStop);
    setOpenD(true);
    RemoveAlert()
    let aux2 = 0;
    setContador(contador2)
    setContadorFormat("02:00")
    interval = setInterval(function () {
      console.log(aux2);
      aux2++;
      let cont = contador2 - aux2;
      console.log(cont);
      setContador(cont)
      setContadorFormat(formatContador(cont))
      if (aux2 == contador2)
        logout()
    }, 1000)
  };

  const formatContador = (seconds) => {
    /*var hour = Math.floor(seconds / 3600);
    hour = (hour < 10) ? '0' + hour : hour;*/
    var minute = Math.floor((seconds / 60) % 60);
    minute = (minute < 10) ? '0' + minute : minute;
    var second = seconds % 60;
    second = (second < 10) ? '0' + second : second;
    //return hour + ':' + minute + ':' + second;
    return minute + ':' + second;
  }

  const handleClose = (reason) => {
    if (reason !== "backdropClick") {
      setOpenD(false);
      if (interval !== null) {
        clearInterval(interval);
      }
      setContador(contador2)
      setContadorFormat("02:00")
      AddAlert();
    }
  };

  const aux = () => {
    clearTimeout(mouseStop);
    mouseStop = setTimeout(function () {
      handleClickOpen();
    }, Time);
  }

  function AddAlert() {
    window.addEventListener('mousemove', aux)
  }
  function RemoveAlert() {
    window.removeEventListener('mousemove', aux);
  }

  return (
    <div>
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route exact path="/">
              <Redirect to={compruebaKey() ? '/aprendizaje' : '/login'} />
            </Route>
            {compruebaKey() === false &&
              <Route exact path={pathroute != '/login' && pathroute != '/reset-password' && pathroute != '/autologin' ? pathroute : '/'}>
                <Redirect to="/login" />
              </Route>
            }
            {compruebaKey() === true &&
              <Route exact path={pathroute == '/login' || pathroute == '/reset-password' || pathroute == '/autologin' ? pathroute : '/login'}>
                <Redirect to="/aprendizaje" />
              </Route>
            }
            <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
            <Route exact path="/autologin" name="Autologin" render={props => <Autologin {...props} />} />
            <Route exact path="/cambia-password" name="Cambiar contraseña" render={props => <FirstChange {...props} />} />
            <Route exact path="/reset-password" name="Cambiar contraseña" render={props => <Cambiarpass {...props} />} />
            <Route exact path="/aprendizaje" name="Mi Aprendizaje" render={props => <Aprendizaje {...props} />} />
            <Route exact path="/mensajes" name="Mensajes" render={props => <Mensajes {...props} />} />
            <Route exact path="/perfil" name="Perfil" render={props => <Perfil {...props} />} />
            <Route exact path="/endscorm" name="Perfil" render={props => <EndScorm {...props} />} />
            <Route exact path="/cursos" name="Cursos" render={props => <Cursos {...props} />} />
            <Route exact path="/curso" name="Curso" render={props => <VisorComponent {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
      <Dialog
        open={openD}
        onClose={(e, r) => { handleClose(r) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alerta de inactividad"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            La sesión se va a cerrar por inactividad en 2 minutos, para evitarlo pulse en el botón de "Cancelar"
            <div>
              {contadorFormat}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e, r) => { handleClose(r) }} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default App;
